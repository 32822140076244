import React from "react";
import classes from './Margin.module.css';

const Margin = () => {
    return (
        <div className={classes.MarginContainer}>
            
        </div>
    );
};

export default Margin;